.produtos {
  background-color: var(--verde);
  padding: 50px;
}
.produtos-container {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  max-width: 1024px;
  margin: 0 auto;
}
.produtos-texto {
  justify-items: center;
  text-align: left;
}
.produtos-titulo {
  color: var(--branco);
  font-family: "Anybody", sans-serif;
  font-size: 45px;
  line-height: 1.2;
}
.produtos-subtitulo {
  color: var(--branco);
  font-size: 20px;
}
.produtos-lista {
  width: 350px;
  list-style: none;
  color: var(--branco);
  line-height: 1.8;
  columns: 2;
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 20px;
}
.gaze {
  white-space: nowrap;
}
.produtos-itens {
  width: 100px;
  margin-left: 0px;
}
.produtos-itens::marker {
  font-size: 12px;
}
.produtos-itens::before {
  content: "•";
  margin-right: 10px;
}
.gaze::before {
  content: "•";
  margin-right: 10px;
}
.botao_comprar {
  background-color: var(--bege);
  color: var(--verde);
  padding: 5px 45px;
  border-radius: 15px;
  font-size: 25px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.5s;
  border: 2px solid transparent;
  text-decoration: none;
}
.botao_comprar:hover {
  background-color: transparent;
  border-color: var(--bege);
  color: var(--bege);
}
.produtos-imagens {
  justify-items: center;
  display: grid;
  align-items: center;
}
.img-epi {
  width: 430px;
}
.img-logokl {
  width: 100px;
  margin-top: 30px;
}

@media (max-width: 1048px) {
  .produtos-container {
    max-width: 780px;
  }
  .produtos-lista {
    font-size: 18px;
    width: 250px;
  }
  .botao_comprar {
    font-size: 22px;
  }
  .img-epi {
    width: 350px;
  }
  .img-logokl {
    width: 90px;
  }
}

@media (max-width: 790px) {
  .produtos {
    padding: 50px 20px;
  }
  .produtos-container {
    max-width: 560px;
  }
  .produtos-titulo {
    font-size: 30px;
  }
  .produtos-subtitulo {
    font-size: 16px;
  }
  .produtos-lista {
    font-size: 16px;
    line-height: 2;
    width: 200px;
  }
  .botao_comprar {
    font-size: 18px;
  }
  .produtos-imagens {
    margin-left: 20px;
  }
  .img-epi {
    width: 210px;
  }
  .img-logokl {
    width: 70px;
  }
}
@media (max-width: 600px) {
  .produtos {
    padding: 50px 30px;
    display: flow-root;
  }
  .produtos-container {
    display: block;
    max-width: 320px;
    text-align: center;
  }
  .produtos-lista {
    width: 230px;
    text-align: left;
  }
  .produtos-texto {
    text-align: center;
  }
  .produtos-titulo {
    font-size: 28px;
    text-align: left;
  }
  .produtos-subtitulo {
    font-size: 15px;
    text-align: left;
  }
  .botao_comprar {
    font-size: 17px;
    margin-bottom: 130px;
    padding: 6px 18px;
    border-radius: 10px;
  }
  .produtos-imagens {
    margin-left: 0px;
  }
  .img-epi {
    width: 240px;
    margin-top: 50px;
  }
  .img-logokl {
    width: 70px;
    margin-top: 50px;
  }
}
@media (max-width: 400px) {
  .produtos-lista {
    width: 180px;
  }
}
@media (max-width: 350px) {
  .produtos-lista {
    width: 160px;
    font-size: 15px;
  }
}
