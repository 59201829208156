.relatos {
  background-color: var(--branco);
  padding: 50px;
}
.relatos-titulo {
  color: var(--verde);
  font-family: "Anybody", sans-serif;
  font-size: 45px;
  max-width: 1024px;
  margin: 0 auto;
}
.relatos-container {
  max-width: 1024px;
  margin: 0 auto;
  list-style: none;
}
.relato-lista {
  list-style: none;
  background-color: var(--bege);
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 20px;
}
.relato-texto {
  color: var(--verde);
  font-size: 20px;
}
.relato-nome {
  font-size: 18px;
  text-align: right;
}
@media (max-width: 780px) {
  .relatos-container {
    max-width: 560px;
  }
  .relatos-titulo {
    font-size: 30px;
  }
  .relato-texto {
    font-size: 18px;
  }
  .relato-nome {
    font-size: 16px;
  }
}

@media (max-width: 560px) {
  .relatos-container {
    max-width: 330px;
  }
  .relatos-titulo {
    font-size: 28px;
    text-align: center;
  }
  .relato-texto {
    font-size: 16px;
  }
  .relato-nome {
    font-size: 15px;
  }
}
