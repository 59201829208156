.rodape {
  background-color: var(--verde);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ffffff2d;
}
.icone {
  color: var(--branco);
}
.assinatura {
  color: var(--branco);
  font-size: 16px;
  font-family: Calmius;
  margin-left: 3px;
}
.assinatura::first-letter {
  font-family: "Yeseva One", serif;
  font-size: 20px;
}
.rodape-mive {
  text-decoration: none;
  color: var(--branco);
  font-size: 16px;
  margin: 0 5px;
  display: flex;
  align-items: center;
}

@media (max-width: 580px) {
  .assinatura {
    font-size: 14px;
  }
}
