@font-face {
  font-family: Calmius;
  src: url(/public/fonts/Calmius-Regular.woff);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --branco: #f1fff1;
  --verde: #4b834c;
  --bege: #e7efe1;
}

body {
  font-family: "Open Sans";
}
html {
  scroll-behavior: smooth;
}
