.sobreNos {
  margin: 120px 100px 80px 100px;
}

.container_sobreNos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.titulo_SobreNos {
  font-size: 45px;
  color: var(--verde);
  font-weight: 700;
  margin-bottom: 50px;
}

.paragrafo_sobreNos {
  font-size: 22px;
  color: var(--verde);
  font-weight: 400;
  line-height: 1.5;
  padding: 0 150px 0 0;
  margin-bottom: 20px;
  text-indent: 15px;
}

.container_imagem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img {
  height: 230px;
}

@media (max-width: 1024px) {
  .sobreNos {
    margin: 80px 60px;
  }
  .paragrafo_sobreNos {
    font-size: 22px;
    padding: 0 100px 0 0;
  }
  .img {
    height: 180px;
  }
}

@media (max-width: 780px) {
  .sobreNos {
    margin: 60px 30px;
  }
  .container_sobreNos {
    display: flex;
    flex-direction: column;
    margin: 0px;
  }
  .container_imagem {
    flex-direction: row;
  }
  .titulo_SobreNos {
    font-size: 30px;
    text-align: center;
  }
  .paragrafo_sobreNos {
    font-size: 19px;
    padding: 0;
    line-height: 1.3;
  }
  .img {
    height: 120px;
    margin-top: 30px;
  }
}

@media (max-width: 560px) {
  .sobreNos {
    margin: 60px 20px;
  }
  .titulo_SobreNos {
    font-size: 28px;
  }
  .paragrafo_sobreNos {
    font-size: 16px;
  }
  .img {
    height: 100px;
  }
}
