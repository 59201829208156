.especialiades {
  display: grid;
  grid-template-columns: 2fr 1fr;
  max-width: 1024px;
  margin: 0 auto 50px auto;
  padding: 0 20px;
}
.especialidades-texto {
  justify-self: center;
  text-align: center;
  margin-top: 45px;
}
.paragrafo-texto {
  color: var(--verde);
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 20px;
  margin-right: 50px;
  text-align: left;
  text-indent: 15px;
}
.botao_contato {
  background-color: var(--verde);
  color: var(--branco);
  padding: 8px 45px;
  border-radius: 15px;
  font-size: 25px;
  cursor: pointer;
  transition: 0.5s;
  border: 3px solid transparent;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 30px;
}
.botao_contato:hover {
  background-color: transparent;
  border-color: var(--verde);
  color: var(--verde);
}
.especialiades-descricao {
  width: 390px;
  text-align: center;
  justify-self: center;
}
.especialidades-lista {
  list-style: none;
  color: var(--verde);
  font-size: 24px;
  border: 3px solid rgba(75, 131, 76, 0.5);
  border-radius: 15px;
  background-color: rgba(241, 255, 241, 0.5);
  padding: 50px 0 50px 35px;
  margin-top: 50px;
  line-height: 1.6;
  text-align: left;
}
.tec-enfermagem {
  margin-left: 0px;
  width: 400px;
}
.li-servicos {
  margin-left: 0px;
  width: 400px;
}
.li-servicos::marker {
  font-size: 12px;
}
.li-servicos::before {
  content: "•";
  margin-right: 10px;
}
.tec-enfermagem-mobile {
  display: none;
}
.tec-enfermagem::before {
  content: "•";
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .especialiades {
    max-width: 780px;
    margin: 0 auto 50px auto;
  }
  .paragrafo-texto {
    font-size: 20px;
  }
  .botao_contato {
    padding: 6px 30px;
    font-size: 22px;
  }
  .especialiades-descricao {
    width: 350px;
  }
  .especialidades-lista {
    font-size: 22px;
    padding: 30px 0 30px 35px;
    margin-top: 40px;
    line-height: 1.5;
    border: 3px solid rgba(75, 131, 76, 0.5);
  }
  .tec-enfermagem {
    margin-left: 0px;
    width: 400px;
  }
  .li-servicos {
    margin-left: 0px;
    width: 400px;
  }
}
@media (max-width: 780px) {
  .especialiades {
    max-width: 560px;
    margin: 45px auto;
  }
  .paragrafo-texto {
    font-size: 18px;
    margin-right: 20px;
  }
  .especialidades-texto {
    margin-right: 10px;
  }
  .botao_contato {
    padding: 6px 20px;
    font-size: 18px;
  }
  .especialiades-descricao {
    width: 210px;
    margin-left: 0px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
  }
  .especialidades-lista {
    width: 210px;
    font-size: 18px;
    padding: 25px 0 25px 10px;
    margin-top: 0px;
    margin-bottom: 80px;
    margin-left: 20px;
    line-height: 1.5;
    border: 2px solid rgba(75, 131, 76, 0.5);
  }
  .li-servicos {
    margin-left: 0px;
    width: auto;
  }
  .tec-enfermagem {
    display: none;
  }
  .tec-enfermagem-mobile {
    margin-left: 0px;
    width: auto;
    display: flex;
  }
  .li-servicos::before {
    margin-right: 5px;
  }
  .tec-enfermagem-mobile::before {
    content: "•";
    margin-right: 5px;
  }
}

@media (max-width: 560px) {
  .especialiades {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-self: center;
    align-items: center;
    max-width: 350px;
    margin: -50px auto 45px auto;
  }
  .especialidades-texto {
    margin-right: 0px;
  }
  .paragrafo-texto {
    font-size: 16px;
    margin-right: 0px;
  }
  .especialiades-descricao {
    width: 200px;
    text-align: center;
    justify-self: center;
    margin-left: 0px;
  }
  .botao_contato {
    background-color: var(--verde);
    padding: 6px 18px;
    border-radius: 10px;
    font-size: 14px;
    margin-left: 0px;
    margin-bottom: 30px;
  }
  .especialidades-lista {
    font-size: 16px;
    border: 2px solid rgba(75, 131, 76, 0.5);
    padding: 20px 0;
    margin-top: 20px;
    line-height: 2;
    margin: 20px 0px 30px 0px;
  }
  .li-servicos::before {
    margin-right: 5px;
  }
  .li-servicos {
    margin-left: 20px;
  }
  .tec-enfermagem-mobile {
    display: inline;
    margin-left: 20px;
    width: auto;
  }
  .tec-enfermagem-mobile::before {
    margin-right: 5px;
    content: "•";
  }
  .tec-enfermagem {
    display: none;
  }
}
